import FeaturePage from 'components/pages/featurePage'
import Head from 'components/head'
import Layout from 'layouts'
import { Link } from 'gatsby'
import Masthead from 'components/mastHead'
import React from 'react'
import img from './masthead.jpg'
import { metaTagData } from 'config'
import { sitePaths } from 'config/paths'
import styled from '@emotion/styled'

interface IPageContext {
  feature: IFeaturedProject
}
interface IProps {
  pageContext: IPageContext
}

const SpanBorder = styled.span`
  display: block;
  background: rgba(255, 255, 255, 0.4);
  width: 50%;
  height: 2px;
  margin: 10px auto 10px;
`

const FeatureTemplate = ({ pageContext }: IProps): React.ReactElement => {
  return (
    <>
      <Head metaTagData={metaTagData} />
      <Layout>
        <Masthead backgroundImage={img}>
          <Link to={sitePaths.appLinks.portfolio} className="lg:text-3xl text-xl font-light text-center">
            Featured Work
          </Link>
          <SpanBorder />
          <h1 className="text-2xl lg:text-5xl font-light text-center">{pageContext.feature.title}</h1>
        </Masthead>
        <FeaturePage feature={pageContext.feature} />
      </Layout>
    </>
  )
}

export default FeatureTemplate
