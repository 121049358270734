import { AppContext, lightBoxVisibleAction } from 'state/context/appContext'

import React from 'react'
import { SiteIcons } from 'components/icons/SiteIcon'
import styled from '@emotion/styled'

interface IProps {
  feature: IFeaturedProject
  className?: string
}
const galleryImageWidth = '175px'

const BlankBox = styled.div`
  width: ${galleryImageWidth};
  height: 0;
`

const Thumbnail = styled.div`
  overflow: hidden;
  position: relative;
  margin: 0rem 0 1rem 0;
  width: ${galleryImageWidth};
  height: 129.75px;
  display: block;
  margin: 1rem;
  border-radius: 7px;
  border-color: #ccc;
  border-width: thin;
  border-style: solid;
  .gallery-item-label {
    position: absolute;
    z-index: 9;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    transition: all 0.2s ease;
    border-radius: 7px;
    display: flex;
  }
  img {
    transition: all 0.5s ease;
    width: 278px;
    height: 160px;
  }
  &:hover {
    .gallery-item-overlay {
      opacity: 0.8;
    }
    img {
      transform: scale(1.1);
    }
    .gallery-item-label {
      margin-top: 0;
      opacity: 1;
    }
  }
  .gallery-item-overlay {
    z-index: 8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1784fb;
    opacity: 0;
    transition: all 0.5s ease;
    border-radius: 7px;
  }
`

const FeatureGallery = ({ feature, className }: IProps): React.ReactElement | null => {
  const { dispatch } = React.useContext(AppContext)
  const imagePath = `/img/portfolio/featured-projects/${feature.key}/gallery`
  const galleryData = [...new Array(feature.galleryCount)]
  const handleOpenGallery = (e: React.MouseEvent | React.KeyboardEvent): void => {
    const photoId = Number(e.currentTarget.getAttribute('data-photo-id'))
    const Images = galleryData.map((_, i) => `${imagePath}/${i + 1}.jpg`)
    dispatch(lightBoxVisibleAction(true, Images, photoId))
  }
  if (feature.galleryCount === undefined) {
    return null
  }
  return (
    <div className={`flex flex-col ${className}`}>
      <h3 className="my-6">Project Images</h3>
      <div className="flex flex-row flex-wrap justify-around">
        {galleryData.map((_, key) => {
          const i = key + 1
          const thumbnail = `${imagePath}/${i}-thumb.jpg`
          return (
            <Thumbnail key={key} role="button" data-photo-id={i} tabIndex={0} className="fadeIn animated flex-grow-0 rounded" onKeyDown={handleOpenGallery} onClick={handleOpenGallery}>
              <div className="gallery-item-label">
                <SiteIcons.ZoomIn size="40" color="white" className="rollover-icon" />
              </div>
              <div className="gallery-item-overlay"></div>
              <img src={thumbnail} alt="" />
            </Thumbnail>
          )
        })}
        <BlankBox />
        <BlankBox />
        <BlankBox />
        <BlankBox />
        <BlankBox />
      </div>
    </div>
  )
}

export default FeatureGallery
