import FeatureGallery from './featureGallery'
import FeatureList from './featureList'
import FeatureMenu from './featureMenu'
import FeatureVideo from './featureVideo'
import React from 'react'
import styled from '@emotion/styled'

const FeaturedImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 7px;
  border-color: #ccc;
  border-width: thin;
  border-style: solid;
`

const FeatureStory = styled.div`
  white-space: pre-wrap;
  p {
    white-space: pre-wrap;
  }
`

interface IProps {
  feature: IFeaturedProject
}

const FeaturePage = ({ feature }: IProps): React.ReactElement => {
  return (
    <div className="container mx-auto lg:mb-20 my-3 overflow-hidden">
      <div className="flex flex-row w-100 mx-4 pt-10">
        <FeatureMenu currentFeatureKey={feature.key} />
        <div className="flex-grow px-4 lg:pb-10 my-10">
          <FeaturedImage src={`/img/portfolio/featured-projects/${feature.key}/featured_thumb.jpg`} alt="" className=" rounded-lg fadeIn animated shadow" />
          <h3 className=" my-6">{`${feature.title} ${feature.description}`}</h3>
          <FeatureStory dangerouslySetInnerHTML={{ __html: feature.story }} />
          <FeatureList list={feature.featureList} className="my-4" />
          <FeatureVideo video={feature.video} className="my-4" />
          <FeatureGallery feature={feature} className="my-4" />
        </div>
      </div>
    </div>
  )
}

export default FeaturePage
