import { Link } from 'gatsby'
import React from 'react'
import featuredProjectData from 'models/featuredProjectData'
import { shuffle } from 'utils'
import { sitePaths } from 'config/paths'
import styleConfig from 'config/styleConfig'
import styled from '@emotion/styled'

interface IProps {
  currentFeatureKey: string
}

const FeatureMenuContainer = styled.div`
  display: none;
  ${styleConfig.breakPoints.lg} {
    display: flex;
    flex-direction: column;
  }
  flex: 0 0 450px;
`
const RandomFeatureItem = styled.li`
  border-bottom: 1px solid #e9e9e9;
  padding: 1rem 0rem;
  margin: 0 2rem 0 0;
  color: #85919d;
  span {
    font-size: 19px;
  }
  &:hover {
    color: #3097d1;
  }
`

const FeatureMenu = (props: IProps): React.ReactElement => {
  const randomFeatures = featuredProjectData.filter((item) => item.key !== props.currentFeatureKey)
  const randomSortFeatures = shuffle<IFeaturedProject>(randomFeatures)
  return (
    <FeatureMenuContainer>
      <div className="pb-4 text-2xl font-light">More Featured Projects</div>
      <ul className="flex flex-col pb-4">
        {randomSortFeatures.map((item, key) => (
          <RandomFeatureItem key={key} className="py-2">
            <Link to={sitePaths.appLinks.portfolioProjects.replace(':id', item.key)} className="flex flex-row">
              <div className="flex-grow-0">
                <img className="rounded" src={`/img/portfolio/featured-projects/${item.key}/featured_thumb.jpg`} alt="" style={{ width: '74', height: '50px' }} />
              </div>
              <div className="pl-4">
                <span dangerouslySetInnerHTML={{ __html: item.title }} />
                <br />
                <small dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            </Link>
          </RandomFeatureItem>
        ))}
      </ul>
      <div className="flex justify-center">
        <Link to={sitePaths.appLinks.portfolio} className="btn text-center w-2/3 lift">
          Back To Portfolio
        </Link>
      </div>
    </FeatureMenuContainer>
  )
}

export default FeatureMenu
