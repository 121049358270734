import React from 'react'

interface IProps {
  list: string[] | undefined
  className?: string
}

const FeatureList = ({ list, className }: IProps): React.ReactElement | null => {
  if (list === undefined) {
    return null
  }
  return (
    <div className={`${className}`}>
      <span>Feature Check List</span>
      <ul>
        {list.map((item, key) => (
          <li key={key} className="p-3">
            <span className="w-5 mr-4 h-5 inline-flex items-center justify-center bg-gray-500 text-white rounded-full flex-shrink-0">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="w-3 h-3" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default FeatureList
