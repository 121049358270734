import React from 'react'
import cn from 'classnames'

interface IProps {
  video: string | undefined
  className?: string
}

const FeatureVideo = ({ video, className }: IProps): React.ReactElement | null => {
  if (video === undefined) {
    return null
  }
  return (
    <div className={cn({ className })}>
      <h3 className="mb-4 text-3xl">Project Video</h3>
      <div className="fadeIn animated pb-20 w-full h-full">
        <iframe title="featureVideo" width="560" height="315" src={video} frameBorder="0" allowFullScreen></iframe>
      </div>
    </div>
  )
}

export default FeatureVideo
